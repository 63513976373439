import 'react-perfect-scrollbar/dist/css/styles.css';
import React from "react";
import { useRoutes} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles'; 
import theme from 'src/theme';
import routes from 'src/routes';
import { SnackbarProvider } from 'notistack'; 
import {isMobile} from 'react-device-detect';
const App = (props) => {      
  const routing = useRoutes(routes(props,isMobile)); 
  return (
   <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SnackbarProvider maxSnack={3}>{routing}</SnackbarProvider>
    </ThemeProvider>
  );
}; 
export default App;
