import React from 'react';
import { Navigate } from 'react-router-dom'; 
import MainLayout from 'src/layouts/MainLayout'; 
import NotFoundView from 'src/views/errors/NotFoundView'; 
import FeedbackForm from 'src/views/wufoo/FeedbackForm';  
import FeedbackForm2 from 'src/views/wufoo/FeedbackForm2';  
import HomeView from 'src/views/wufoo/HomeView';  
const routes =(props,isMobile)=>{    
  return [  
  {
    path: '/',
    element: <MainLayout/>,
    children: [ 
      { path: '404', element: <NotFoundView props={props} isMobile={isMobile}/> }, 
      /* { path: '/', element: <HomeView props={props} isMobile={isMobile}/> },  */
/*       { path: '1', element: <FeedbackForm props={props} view={"step1"}  isMobile={isMobile}/> }, 
      { path: '1/reports', element: <FeedbackForm props={props} view={"step3"} isMobile={isMobile}/> },  */
      /* { path: '2', element: <FeedbackForm2 props={props} view={"step1"}  isMobile={isMobile}/> }, 
      { path: '2/reports', element: <FeedbackForm2 props={props} view={"step3"} isMobile={isMobile}/> },  */
      { path: '/', element: <FeedbackForm props={props} view={"step1"}  isMobile={isMobile}/> }, 
      { path: '/reports', element: <FeedbackForm props={props} view={"step3"} isMobile={isMobile}/> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
]};

export default routes;
