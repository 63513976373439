import { createMuiTheme } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography'; 

const theme = createMuiTheme({ 
  palette: {
    background: {
      dark: '#ffffff',
      default: "#c8352f70",
      paper: "#c8352f"
    },
    primary: {
      main: "#7c50a0"
    },
    secondary: {
      main:"#7c50a0"
    },
    text: {
      default:"#ffffff",
      primary: "#000000",
      secondary: "#1cadd5",
    }
  },
  shadows,
  typography
});

export default theme;
