import React from 'react'; 
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';  
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  } 
})); 
const FeedbackForm = () => {
  const classes = useStyles();  
  return (
    <>
    <Page className={classes.root} title="Home"> 
        <Box           
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
        <Container maxWidth="md">
          <></>
        </Container>
        </Box> 
    </Page>
   </>
  );
};

export default FeedbackForm;
