import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Skeleton } from '@material-ui/lab'; 
import { purple } from '@material-ui/core/colors';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container, 
  TextField,
  Typography,
  makeStyles,
  Grid,
  withStyles,
  Divider
} from '@material-ui/core';
import Page from 'src/components/Page'; 
import axios from 'axios';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 400
  },
  title: {
    fontSize: '35px',
    fontFamily: ['formatabold', 'sans-serif'] ,
    fontStyle:"normal",
    fontWeight:700
  },
  subtitle: {
    fontSize: '20px',   
    fontFamily: ['formataregular', 'sans-serif'] ,
    fontStyle:"normal",
    fontWeight:500
  },
  successTitle: {
    fontSize: '35px', 
    fontFamily: ['formataregular', 'sans-serif'] ,
    fontStyle:"normal",
    fontWeight:500
  },
  successMsg: {
    fontSize: '20px',
    fontFamily: ['formataregular', 'sans-serif'] ,
    fontStyle:"normal",
    fontWeight:500
  },
  inputLabel: {
    fontSize: '20px',
    fontFamily: ['formataregular', 'sans-serif'] ,
    fontStyle:"normal",
    fontWeight:500
  },
  comment: {
    fontSize: '14px', 
    fontFamily: ['formataregular', 'sans-serif'] ,
    fontStyle:"normal",
    fontWeight:500
  },
  commentBy: {
    fontSize: '14px', 
    fontFamily: ['formataregular', 'sans-serif'] ,
    fontStyle:"normal",
    fontWeight:700
  },
  input:{
    borderRadius:0
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#1cadd5 !important"
  },
  ViveVC: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
 
}));

const FeedbackForm = (props) => {
  const classes = useStyles();
  let navigate = useNavigate ();
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [field3Labels, setField3Labels] = useState('1. Is Warholʼs Ladies and Gentlemen portraits, shining light on an under represented group or exploiting his models?');
  const [field14Labels, setField14Labels] = useState('Name');
  const [currentView, setCurrentView] = useState('step3');
  useEffect(() => {
    setCurrentView(props.view);
  }, [props.view]);
  useEffect(() => {
    dataLoader();
  }, []);
  useEffect(() => {
    fieldLoader();
  }, []);
  const fieldLoader = async event => {
    /* 'https://artgalleryofontario.wufoo.com/api/v3/forms/mqf8ztu00hwl2n/fields.json', */
    setIsLoading(true);
    var apiurl =
      'https://phv6eg380l.execute-api.us-east-1.amazonaws.com/api/fetch';
    var data = {
      url:'https://artgalleryofontario.wufoo.com/api/v3/forms/mdmpyte0tfjsv8/fields.json',        
      method: 'GET',
      type: 'faith',
      data: {}
    };
    const response = await axios.post(apiurl, data);
    console.log("response",response);
    response.data && response.data.Fields.length>0 && response.data.Fields.forEach(async element => {
        if(element.ID==='Field3')
        {
         await setField3Labels(element.Title);
        }
        if(element.ID==='Field14')
        {
          await setField14Labels(element.Title);
        }
    }); 
    setIsLoading(false);
  };
  const dataLoader = async event => {
    setIsLoading(true);
    var apiurl =
      'https://phv6eg380l.execute-api.us-east-1.amazonaws.com/api/fetch';
    var data = {
      url:
        /* 'https://artgalleryofontario.wufoo.com/api/v3/forms/mdmpyte0tfjsv8/entries.json?sort=EntryId&sortDirection=DESC&Filter1=Field117+Is_equal_to+yes', */
        'https://artgalleryofontario.wufoo.com/api/v3/forms/mdmpyte0tfjsv8/entries.json?sort=EntryId&sortDirection=DESC&Filter1=Field117+Is_equal_to+yes',
      method: 'GET',
      type: 'faith',
      data: {}
    };
    const response = await axios.post(apiurl, data);
    await setEventData(response.data);
    console.log(response);
    console.log(response.data);
    setIsLoading(false);
  };
  const formSubmission = async e => {
    setIsLoading(true);
    var apiurl =
      'https://phv6eg380l.execute-api.us-east-1.amazonaws.com/api/fetch';
    var data = {
      url:
        'https://artgalleryofontario.wufoo.com/api/v3/forms/mdmpyte0tfjsv8/entries.json',
      method: 'POST',
      type: 'faith',
      data: {
        Field3: e.feedback,
        Field14: e.name
      }
    };
    const response = await axios.post(apiurl, data);
    await setEventData(response.data);
    console.log(response);
    console.log(response.data);
    setIsLoading(false);
  };
  function SkeltonLoader() {
    return (
      <Container maxWidth="sm">
        <Box mb={3}>
          <Grid
            container
            justify="center"
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Skeleton variant="rect" width={552} height={69} />
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          justify="center"
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Skeleton variant="rect" width={552} height={52} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={552} height={52} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={552} height={52} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={552} height={52} />
          </Grid>
        </Grid>
      </Container>
    );
  }
  const WhiteButton = withStyles(theme => ({
    root: {
      color: "#000",
      fontFamily: ['formatabold', 'sans-serif'] ,
      fontStyle:"normal",
      fontWeight:500,
      backgroundColor: theme.palette.background.dark,
      '&:hover': {
        backgroundColor: theme.palette.background.dark,
        border:"none",
        boxShadow:"none"
      },
      border:"none",
      boxShadow:"none"
    }
  }))(Button);
  const ColorButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      fontFamily: ['formatabold', 'sans-serif'] ,
      fontStyle:"normal",
      fontWeight:500,
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.default
      }
    }
  }))(Button);
  return (
    <>
    <Page className={classes.root} title="Faith And Fortune User Engagement">
      {isLoading && (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <SkeltonLoader />
        </Box>
      )}
      {!isLoading && (
        <Box
          /*    display="flex" */
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          {currentView === 'step1' ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  feedback: '',
                  name: ''
                }}
                validationSchema={Yup.object().shape({
                  feedback: Yup.string()
                    .max(1500)
                    .required('Comment is required'),
                  name: Yup.string()
                    .max(150)
                    .required('Name is required')
                })}
                onSubmit={async (values, { resetForm }) => {
                  await formSubmission(values);
                  resetForm({ values: '' });
                  setCurrentView('step2');
                  await dataLoader()
                  setTimeout(() => {
                    /* setCurrentView('step3'); */
                    /* navigate("/1/reports"); */
                    navigate("/reports");
                  }, 3000);                  
                  console.log(
                    '(JSON.stringify(values, null, 2)',
                    JSON.stringify(values, null, 2)
                  );
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box mb={1} mt={2}>
                      <Typography
                        className={classes.inputLabel}
                        color="textPrimary"
                      >
                       {field3Labels}
                      </Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.feedback && errors.feedback)}
                      fullWidth
                      helperText={touched.feedback && errors.feedback}
                      multiline
                      rows={4} 
                      InputProps={{
                        classes: {
                          root: classes.input,
                          notchedOutline: classes.notchedOutline
                        },
                      }}
                      name="feedback"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.feedback}
                      variant="outlined"
                    />
                    <Box mb={1} mt={2}>
                      <Typography
                        color="textPrimary"
                        className={classes.inputLabel}
                      >
                      {field14Labels}
                      </Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      name="name"
                      InputProps={{
                        classes: {
                          root: classes.input,
                          notchedOutline: classes.notchedOutline
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                    <Box mt={2}>
                      <ColorButton
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        type="submit" 
                        style={props.isMobile?{
                          margin: '0',
                          width:"100%", 
                          right: '0',
                          bottom: 63,                                
                          position: 'fixed',
                          height:55,
                          borderRadius:0                                
                      }:{                        
                        width:"100%",
                        borderRadius:0                                                     
                    }}
                      >
                        SUBMIT
                      </ColorButton>
                    </Box>
                    <Box mt={2}>
                      <WhiteButton
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth 
                        /* onClick={()=>{ navigate("/1/reports");  }} */
                        onClick={()=>{ navigate("/reports"); /* setCurrentView("step3") */}}
                        style={props.isMobile?{
                          margin: '0',
                          width:"100%", 
                          right: '0',
                          bottom: 0,                                
                          position: 'fixed',
                          height:55,
                          borderRadius:0                                
                      }:{                        
                        width:"100%",
                        borderRadius:0                                                     
                    }}
                      >
                        VIEW OTHER RESPONSES
                      </WhiteButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : currentView === 'step2' ? (
            <Container  maxWidth="md">
              <div className={classes.ViveVC}>
              <Box  mb={4}>
                <Typography className={classes.successTitle} color="textSecondary">
                  Thank you for the submission
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.successMsg} color="textPrimary">
                  Once your response is approved, it will be appear in the
                  community board. You will now be redirected to the community
                  board to see other visitors responses.
                </Typography>
              </Box>
              </div>
            </Container>
          ) : currentView === 'step3' ? (
         <>   <Container maxWidth="md">
              <Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  className={classes.title}                  
                >
                  What others are saying
                </Typography>
                <Typography
                  className={classes.subtitle}
                  color="textPrimary"                  
                >
                 {field3Labels}
                </Typography>
                </Box>
                <Divider />
                <Box mt={2}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={5}                   
                  >
                    {eventData.Entries &&
                      eventData.Entries.map((node, index) => (
                        <Grid xs={12} key={index} item>
                          <Box mb={1}>
                            <Typography
                              className={classes.comment}
                              color="textPrimary"
                              fontWeight="fontWeightBold"
                            >
                              {node.Field3}
                            </Typography>
                          </Box>
                          <Typography
                            className={classes.commentBy}
                            color="textPrimary" 
                          >
                            {node.Field14}
                          </Typography>
                        </Grid>
                      ))}
                    <Grid xs={12} item>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={5}
                      >
                        <Grid xs={12} item>
                          {!isLoading && (
                            <ColorButton
                              variant="contained"
                              onClick={() => {
                               /*  setCurrentView('step1'); */
                                  navigate("/");
                              }}
                              color="primary"
                              fullWidth
                              type="submit"
                              style={props.isMobile?{
                                margin: 'auto',
                                width:"100%", 
                                right: '0',
                                bottom: 0,                                
                                position: 'fixed',
                                height:55,
                                borderRadius:0
                            }:{
                              margin: 'auto',
                              width:"50%", 
                              right: '25%',
                              bottom: 20,
                              left: '25%',
                              position: 'fixed',
                              borderRadius:0                                
                          }}
                            
                            >
                              LEAVE ANOTHER RESPONSE
                            </ColorButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
            </>
          ) : null}
        </Box>
      )}
    </Page>
   </>
  );
};

export default FeedbackForm;
